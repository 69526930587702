const { bulkSnapshotsFromCsv } = require('./shipment-bulk-snapshot-from-allocation-sheet')
const docIdToShipmentId = require('../../lib/shipment/tools/to-shipment-id')
module.exports = shipmentUpload

async function shipmentUpload (state, {
  funderShipments,
  routes,
  crypto,
  routesApi,
  shipmentApi
}) {
  await routesApi.create({ routeData: routes })

  const allSnapshots = []
  for (const shipment of Object.values(funderShipments)) {
    const snapshots = await bulkSnapshotsFromCsv(state, shipment)
    const processedSnapshots = await Promise.all(
      snapshots.map(async (snapshot) => {
        if (!snapshot.otpKey) {
          const shipmentId = docIdToShipmentId(snapshot.id)
          const otpCode = await shipmentApi.otp.pg.getPassword(shipmentId)
          if (!otpCode && crypto) {
            const snapshotId = snapshot.id
            const { password, key } = await shipmentApi.otp.generate(crypto)
            await shipmentApi.otp.couch.createKeySnapshot(snapshotId, key)
            await shipmentApi.otp.pg.setPassword(shipmentId, password)
          }
        }
        return snapshot
      })
    )

    const advanceSnapshots = await shipmentApi.bulkAdvanceSnapshots({ shipments: processedSnapshots })
    allSnapshots.push(...advanceSnapshots)
  }
  return allSnapshots
}
