import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Button, UppercaseLabel } from '@fielded/shared-ui'
import { Arrow, Adjust } from '@fielded/shared-ui/src/icons'

import ReportStatus from '../report-status/ReportStatus'
import ReportMeta from '../report-meta/ReportMeta'
import {
  STATUS_NOT_STARTED,
  STATUS_IN_PROGRESS,
  STATUS_COMPLETE,
  STATUS_OFFLINE,
  STATUS_SAVED,
  STATUS_SUBMITTED
} from '../../common/report-status'

const YourLocation = ({ canSubmit, name, target, status, lastSubmittedAt, level, submitPartial }) => {
  let buttonLabelsByStatus = {}
  buttonLabelsByStatus[STATUS_COMPLETE] = 'Update report'
  buttonLabelsByStatus[STATUS_IN_PROGRESS] = 'Continue report'
  buttonLabelsByStatus[STATUS_NOT_STARTED] = 'Start report'
  buttonLabelsByStatus[STATUS_OFFLINE] = 'Start report'
  buttonLabelsByStatus[STATUS_SAVED] = 'Update report'
  buttonLabelsByStatus[STATUS_SUBMITTED] = 'Update report'

  const buttonLabel = buttonLabelsByStatus[status] || 'Go to report'

  return (
    <div className='your-location'>
      <div className='your-location__info'>
        <div className='your-location__label'>
          <div className='your-location__icon'>
            <svg id='icon-map-marker' height='14' width='8' viewBox='0 0 16 28'>
              <title>map-marker</title>
              <path d='M12 10c0-2.203-1.797-4-4-4s-4 1.797-4 4 1.797 4 4 4 4-1.797 4-4zM16 10c0 0.953-0.109 1.937-0.516 2.797l-5.688 12.094c-0.328 0.688-1.047 1.109-1.797 1.109s-1.469-0.422-1.781-1.109l-5.703-12.094c-0.406-0.859-0.516-1.844-0.516-2.797 0-4.422 3.578-8 8-8s8 3.578 8 8z' />
            </svg>
          </div>
          <UppercaseLabel>Your {level}</UppercaseLabel>
        </div>
        <div className='your-location__name'>
          {name}
        </div>
        <div className='your-location__status'>
          <div className='your-location__status-item'>
            <ReportStatus status={status} />
          </div>
          {lastSubmittedAt && (
            <div className='your-location__status-item'>
              <ReportMeta status={status} time={lastSubmittedAt} />
            </div>
          )}
        </div>
      </div>
      <div className='your-location__action'>
        {canSubmit && target && (
          <Button
            component={Link}
            to={submitPartial ? `${target}/partial` : target}
            icon={submitPartial ? <Adjust /> : <Arrow direction='right' />}
            iconSide='right'
            colorVariant='brand'
            fill={submitPartial ? 'outline' : 'full'}
          >
            {submitPartial ? 'Adjust Count' : buttonLabel}
          </Button>
        )}
      </div>
    </div>
  )
}

YourLocation.propTypes = {
  name: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  level: PropTypes.string,
  status: PropTypes.string.isRequired
}

YourLocation.defaultProps = {
  level: 'location'
}

export default YourLocation
